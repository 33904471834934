import { Component, Inject, OnInit, Optional } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ContextProvider, GoogleLocationProvider, GoogleWindow, LocationProvider, ModalService, SharedTermsTranslationKey, ThemeProvider, ThemeService,
    UfLocationProvider, WindowWrapper } from '@unifii/library/common';
import { Client, StructureNode, Theme } from '@unifii/sdk';

import { DeviceRegistrationService } from 'capacitor/device-registration.service';
import { DeviceService } from 'capacitor/device.service';
import { PushNotificationData, PushNotificationService } from 'capacitor/push-notification.service';
import { VibrateService } from 'capacitor/vibrate.service';
import { Config } from 'config';
import { ExternalPath } from 'discover/discover-constants';
import { ShellService } from 'shell/core/shell.service';
import { InitStep } from 'shell/init-step';
import { NavigationService } from 'shell/nav/navigation.service';
import { FormDataPath } from 'shell/shell-constants';
import { ComponentTitleRouteData } from 'shell/shell-model';

const createMapProvider = (
    config: Config,
    window: GoogleWindow,
    translateService: TranslateService,
    contextProvider: ContextProvider,
): LocationProvider => {

    if (config.unifii?.tenantSettings?.googleMapsApiKey) {
        return new GoogleLocationProvider(translateService, window, config.unifii.tenantSettings.googleMapsApiKey, contextProvider);
    }

    return new UfLocationProvider(translateService, window);
};

@Component({
    selector: 'us-app',
    templateUrl: './app.html',
    styles: [`:host { background: white; }`],
    providers: [
        {
            provide: LocationProvider, useFactory: createMapProvider,
            deps: [Config, WindowWrapper, TranslateService, ContextProvider],
        },
    ],
})
export class AppComponent implements OnInit {

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private client: Client,
        private shell: ShellService,
        private nav: NavigationService,
        private deviceService: DeviceService,
        private notificationService: PushNotificationService,
        private translate: TranslateService,
        private modalService: ModalService,
        private vibrateService: VibrateService,
        private deviceRegistrationService: DeviceRegistrationService,
        @Inject(Config) private config: Config,
        @Inject(ThemeProvider) private themeService: ThemeService,
        @Optional() @Inject(InitStep) private initSteps?: InitStep[],
    ) {
        this.initTheme(this.config.themeConfig?.cssVariables ?? this.config?.theme, this.config.unifii?.productBackground);

        this.nav.init();
        this.nav.navigationEnd.subscribe((node) => { this.shell.setTitle(this.getAppTitle(node)); });
    }

    ngOnInit() {
        this.client.start = () => { this.shell.busy = true; };
        this.client.end = () => { this.shell.busy = false; };

        // init notifications
        this.notificationService.notifications.subscribe((n) => {
            void this.pushNotificationModal(n);
        });

        // setup push notifications
        if (this.deviceService.isNative()) {
            void this.notificationService.init().then((token) => {

                if (!token) {
                    console.warn('NotificationService no token found!');

                    return;
                }

                this.deviceService.notificationToken = token;

                void this.deviceRegistrationService.register();
            });
        }

        if (!this.initSteps) {
            return;
        }

        for (const step of this.initSteps) {
            step.run();
        }

        // mobile deepLinks
        this.deviceService.initDeepLinks();

        // splashscreen
        void this.deviceService.hideSplashScreen();
    }

    private async pushNotificationModal(n: PushNotificationData) {
        // Route info
        const routeCommands = this.nav.routeInfoToCommands(n.route);
        const matchTenant = routeCommands && this.config.unifii.tenant === n.route?.tenant;
        const matchProject = routeCommands && this.config.unifii.projectId === n.route?.projectId;
        const sameRoute = routeCommands && this.nav.getCommandsFromSnapshot(this.router.routerState.snapshot).join('/') === routeCommands.join('/');

        // Can not redirect, routeInfo is missing or invalid or match current position
        if (!routeCommands || (matchTenant && matchProject && sameRoute)) {
            // show notification as toast if not action
            if (!n.action) {
                this.vibrateService.vibrate(500);
                void this.modalService.openAlert({
                    title: n.title,
                    message: n.message,
                });
            }

            // App lunched by this notification, nothing to do
            return;
        }

        let userConsent: boolean | undefined = true;

        if (!n.action) {
            this.vibrateService.vibrate(500);
            userConsent = await this.modalService.openConfirm({
                title: n.title,
                message: n.message,
                confirmLabel: this.translate.instant(SharedTermsTranslationKey.ActionView),
                cancelLabel: this.translate.instant(SharedTermsTranslationKey.ActionCancel),
            });
        }

        if (userConsent && n.route) {
            const { tenant, projectId, nodeId, bucket, id } = n.route;
            const redirectCommands: any[] = [ExternalPath, tenant, projectId];

            if (nodeId) {
                redirectCommands.push('n', nodeId);
            }

            if (bucket && id) {
                redirectCommands.push(FormDataPath, bucket, id);
            }

            if (bucket && !id) {
                redirectCommands.push(bucket);
            }

            void this.router.navigate(redirectCommands);
        }
    }

    private initTheme(theme?: Theme, productBackground?: string) {
        if (theme) {
            this.themeService.theme = theme;
        }

        if (productBackground) {
            this.themeService.setStyle('ui-main-background-image', `url("${productBackground}")`);
        }
    }

    private getAppTitle(node?: StructureNode | null): string | undefined {

        if (node?.nodeId) {
            return node.name;
        }

        let title: string | undefined;
        let child = this.route.firstChild;

        while (child) {
            // Store title
            if (child.snapshot.data && (child.snapshot.data).titleTranslationKey as ComponentTitleRouteData) {
                title = this.translate.instant(
                    (child.snapshot.data as ComponentTitleRouteData).titleTranslationKey as string,
                    (child.snapshot.data as ComponentTitleRouteData).titleParams,
                );
            }
            // Look for deeper route title (more specific)
            if (child.firstChild) {
                child = child.firstChild;
            } else {
                child = null;
            }
        }

        return title;
    }

}

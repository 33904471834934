import { Component, OnInit, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ContextProvider, FormDefinitionMetadataIdentifiers, HierarchyFunctions } from '@unifii/library/common';
import { FormData, HierarchyUnitFormData, Query, SortDirections, UfError, ensureUfRequestError, isStringNotEmpty } from '@unifii/sdk';
import { format, isAfter, set } from 'date-fns';

import { BucketIdentifier } from 'discover/components/sign-in/sign-in-constants';
import { DiscoverTranslationKey } from 'discover/discover.tk';
import { AppError } from 'shell/errors/errors';
import { ShellFormService } from 'shell/form/shell-form.service';

interface HistoryItem {
    title?: string;
    signIn: string;
    month: string;
    signOut?: string;
    id: string;
    state: 'SignedOut' | 'SignedIn';
    multiday: boolean;
}

@Component({
    selector: 'ud-sign-in-history',
    templateUrl: './sign-in-history.html',
    styleUrls: ['./sign-in-history.less'],
    providers: [ShellFormService],
})
export class SignInHistoryComponent implements OnInit {

    protected readonly discoverTK = DiscoverTranslationKey;
    protected readonly bucket = BucketIdentifier;
    protected history: HistoryItem[] = [];
    protected activeSignIn?: FormData;
    protected error?: AppError;
    protected busy?: boolean;

    private route = inject(ActivatedRoute);
    private router = inject(Router);
    private formService = inject(ShellFormService);
    private contextProvider = inject(ContextProvider);
    private translate = inject(TranslateService);

    ngOnInit() {
        void this.update(true);
    }

    async update(redirect = false) {
        try {
            this.busy = true;

            const { user } = this.contextProvider.get();

            if (!user) {
                throw new UfError(this.translate.instant(this.discoverTK.SignInErrorLoadingHistory) as string);
            }

            const query = new Query()
                .eq(FormDefinitionMetadataIdentifiers.CreatedBy, user.username)
                .sort(FormDefinitionMetadataIdentifiers.CreatedAt, SortDirections.Descending)
                .limit(30);

            this.formService.bucket = BucketIdentifier;

            const forms = await this.formService.query(query);

            this.history = forms.map((f) => this.signInMapper(f));
            this.activeSignIn = this.history.find((data) => data.state !== 'SignedOut');

            if (!this.activeSignIn && redirect) {
                void this.router.navigate(['new', this.route.snapshot.params], { relativeTo: this.route });
            }

        } catch (e) {
            this.error = ensureUfRequestError(e);
        } finally {
            this.busy = false;
        }
    }

    protected signIn() {
        void this.router.navigate(['new', this.route.snapshot.params], { relativeTo: this.route });
    }

    private signInMapper({ location, hierarchy, signIn, signOut, id, _state }: FormData): HistoryItem {

        const titleItems = [
            hierarchy ? HierarchyFunctions.pathToDisplay((hierarchy as HierarchyUnitFormData).path) : undefined,
            location?._display as string | undefined,
        ].filter(isStringNotEmpty);

        return {
            title: titleItems.join(' - '),
            month: format(new Date(signIn as string), 'MMMM'),
            signIn,
            signOut,
            id: id ?? '',
            state: _state as 'SignedIn' | 'SignedOut',
            multiday: this.isMultiDay(signIn, signOut),
        };
    }

    private isMultiDay(signIn: string, signOut?: string): boolean {

        if (!signOut) {
            return false;
        }

        const signInDate = set(new Date(signIn), { minutes: 0, hours: 0, seconds: 0 });
        const signOutDate = set(new Date(signOut), { minutes: 0, hours: 0, seconds: 0 });

        return isAfter(signOutDate, signInDate);
    }

}

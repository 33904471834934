import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { DataDescriptorAdapterCache } from '@unifii/library/common';
import { MeClient, TenantClient } from '@unifii/sdk';

import { Config } from 'config';
import { DiscoverContext } from 'discover/discover-context';
import { TitleConfig } from 'shell/core/title-config';
import { Authentication } from 'shell/services/authentication';
import { PermissionsFunctions } from 'shell/services/permissions-functions';
import { UserAccessManager } from 'shell/services/user-access-manager';

import { ProjectSelectionPath, UserAccessRootPath } from './discover-constants';

export const userAndProjectGuard = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => new UserAndProjectGuard(
    inject(Config),
    inject(DiscoverContext),
    inject(Authentication),
    inject(TenantClient),
    inject(MeClient),
    inject(UserAccessManager),
    inject(TitleConfig),
    inject(DataDescriptorAdapterCache),
).canActivate(route, state);

class UserAndProjectGuard {

    constructor(
        private config: Config,
        private context: DiscoverContext,
        private auth: Authentication,
        private tenantClient: TenantClient,
        private meClient: MeClient,
        private accessManager: UserAccessManager,
        private titleConfig: TitleConfig,
        private dataDescriptorAdapterCache: DataDescriptorAdapterCache,
    ) { }

    async canActivate(_route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {

        // Guard access token
        if (!this.auth.isAuthenticated || (this.auth.userInfo && this.auth.userInfo.changePasswordOnNextLogin)) {
            void this.accessManager.deny({ grantedRedirectURL: state.url });

            return false;
        }

        // Guard selected project
        if (!this.context.project) {
            void this.accessManager.deny({ grantedRedirectURL: state.url, deniedRedirectURL: ['/', UserAccessRootPath, ProjectSelectionPath] });

            return false;
        }

        // Reset data descriptor cache
        this.dataDescriptorAdapterCache.reset();

        // TODO deal with offline
        const timeout = new Promise<void>((resolve) => setTimeout(() => { resolve(); }, 500));

        await Promise.race([this.updateUserAndProject(), timeout]);

        return true;
    }

    private async updateUserAndProject(): Promise<void> {

        try {
            this.auth.userInfo = await this.meClient.get();
            const permissions = await this.meClient.getPermissions();

            this.auth.userPermissions = PermissionsFunctions.normalizePermissions(permissions);
            this.context.project = await this.tenantClient.getProject(this.config.unifii.projectId);
            this.titleConfig.appTitle = this.context.project.name;
        } catch (e) {
            console.warn('update user and project failed');
        }
    }

}

import { Component, OnInit, ViewChild, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CommonTranslationKey, CreatePasswordConfig, SharedTermsTranslationKey, ToastService, UfControl, UfCreatePasswordComponent, UfFormBuilder } from '@unifii/library/common';
import { Client, ErrorType, Interceptor, MeClient, PasswordDetails, TenantClient, UserInfo, ensureUfRequestError, isDictionary } from '@unifii/sdk';

import { Config } from 'config';
import { ProjectSelectionPath, UserAccessRootPath } from 'discover/discover-constants';
import { DiscoverTranslationKey } from 'discover/discover.tk';
import { ErrorService } from 'shell/errors/error.service';
import { AppError } from 'shell/errors/errors';
import { Authentication } from 'shell/services/authentication';

type PasswordChangeLinkParams = {
    tenant?: string;
    token?: string;
};

/**
 * Via changePasswordOnNextLogin after successful login
 *  Update via Provisioning.updatePassword as authenticated user
 *
 * Via email link
 *  Update via Provisioning.updatePassword as anonymous user with token
 */
@Component({
    selector: 'ud-password-change',
    templateUrl: 'password-change.html',
})
export class PasswordChangeComponent implements OnInit {

    @ViewChild(UfCreatePasswordComponent) private createPasswordComponent?: UfCreatePasswordComponent;

    protected readonly sharedTermsTK = SharedTermsTranslationKey;
    protected readonly discoverTK = DiscoverTranslationKey;
    protected passwordConfig: CreatePasswordConfig;
    protected control: UfControl;
    protected linkError?: AppError;
    protected submitError?: AppError;
    protected user: UserInfo;
    protected busy: boolean;
    protected isViaLink: boolean;

    private token?: string;
    private tenant?: string;
    private router = inject(Router);
    private route = inject(ActivatedRoute);
    private authentication = inject(Authentication);
    private errorService = inject(ErrorService);
    private translate = inject(TranslateService);
    private meClient = inject(MeClient);
    private toastService = inject(ToastService);
    private ufb = inject(UfFormBuilder);
    private interceptor = inject(Interceptor);
    private config = inject(Config);

    ngOnInit() {
        const params = this.route.snapshot.queryParams as PasswordChangeLinkParams;

        this.tenant = params.tenant;
        this.token = params.token;
        this.isViaLink = !!this.token && !!this.tenant;
        this.control = this.ufb.control({ value: {} });
        this.passwordConfig = {
            showOldPassword: !this.isViaLink && !this.authentication.oldPassword,
            isRequired: true,
            showStrengthIndicator: true,
            canCopy: true,
            canGenerate: true,
        };

        if (this.isViaLink) {
            void this.configureViaLinkMode();
        }
    }

    protected async submit() {
        this.control.setSubmitted();

        // Verify validity of password input internal control validators
        if (!this.createPasswordComponent?.groupControl.valid) {
            return;
        }

        try {
            this.submitError = undefined;
            this.busy = true;

            const commands: any[] = ['/', UserAccessRootPath];
            const passwordDetails: PasswordDetails = {
                oldPassword: this.oldPassword,
                password: this.control.value.password,
            };

            if (this.isViaLink && this.token) {
                // Use anonymous call
                await this.meClient.resetPassword(passwordDetails, this.token);
            } else {
                // Already logged in, use authenticated call
                await this.meClient.updatePassword(passwordDetails);
                // Update my changePasswordOnNextLogin status
                this.authentication.userInfo = await this.meClient.get();

                // old logic breaks in workers R&S contractors guard
                commands.push(ProjectSelectionPath);
            }

            this.toastService.success(this.translate.instant(CommonTranslationKey.FeedbackSuccess));
            this.authentication.oldPassword = undefined; // remove old password

            void this.router.navigate(commands);

        } catch (e) {
            const error = ensureUfRequestError(e);

            switch (error.type) {
                case ErrorType.Unauthorized:
                    this.submitError = this.errorService.createError(this.errorService.passwordChangeInvalidLinkErrorMessage);

                    return;
                case ErrorType.Validation:
                    if (isDictionary(error.data) && error.data.code === 'InvalidPassword') {
                        this.submitError = this.errorService.createError(this.errorService.passwordChangeInvalidPasswordErrorMessage);
                    } else if (isDictionary(error.data) && error.data.code === 'PasswordNotChanged') {
                        this.submitError = this.errorService.createError(this.errorService.passwordChangeCannotBeSameErrorMessage);
                    } else {
                        this.submitError = this.errorService.createError(this.errorService.passwordChangeGenericErrorMessage);
                    }
                    break;
                default:
                    this.submitError = this.errorService.createError(this.errorService.passwordChangeGenericErrorMessage);
            }

        } finally {
            this.busy = false;
        }
    }

    protected logout() {
        this.authentication.logout();
    }

    private async configureViaLinkMode() {
        const tokenClient = new Client(this.config.unifii, undefined, this.interceptor);
        const tokenTenantClient = new TenantClient(tokenClient);
        const tokenMeClient = new MeClient(tokenClient);

        try {
            this.busy = true;

            // apply tenant from link param
            this.config.unifii.tenant = this.tenant;
            this.config.unifii.tenantSettings = await tokenTenantClient.getSettings();
            this.user = await tokenMeClient.get(this.token);
        } catch (error) {
            this.linkError = this.errorService.createError(
                this.translate.instant(DiscoverTranslationKey.PasswordChangeErrorInvalidLink),
            );
        } finally {
            this.busy = false;
        }
    }

    private get oldPassword(): string {

        if (this.authentication.oldPassword) {
            return this.authentication.oldPassword;
        }

        return this.control.value?.oldPassword ?? '';
    }

}

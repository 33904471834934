import { Inject, Injectable } from '@angular/core';
import { StorageWrapper } from '@unifii/library/common';
import { TokenStorage, decrypt, encrypt } from '@unifii/sdk';

import { Config, Environment } from 'config';

const TokenKey = 'UfToken';
const RefreshTokenKey = 'UfRefreshToken';
const ExpiresAt = 'UfExpiresAt';

@Injectable()
export class TokenService implements TokenStorage {

    private tokenCache: CryptoKey;

    constructor(
        @Inject(StorageWrapper) private storage: StorageWrapper,
        @Inject(Environment) private config: Config,
    ) { }

    get token(): string {
        return this.storage.getItem(TokenKey);
    }

    set token(v: string) {
        this.setStorageItem(TokenKey, v);
    }

    get expiresAt(): string {
        return this.storage.getItem(ExpiresAt);
    }

    set expiresAt(v: string) {
        this.setStorageItem(ExpiresAt, v);
    }

    async setRefreshToken(v: string | null): Promise<void> {

        if (v) {
            const { cryptoKey, byteString } = await encrypt(this.tokenEncryptionKey, v);

            this.tokenCache = cryptoKey;
            v = byteString;
        }

        this.setStorageItem(RefreshTokenKey, v);
    }

    getRefreshToken(): Promise<string | null> {

        const byteString = this.storage.getItem(RefreshTokenKey);

        if (!byteString) {
            return Promise.resolve(null);
        }

        return decrypt({ key: this.tokenEncryptionKey, byteString, cryptoKey: this.tokenCache });
    }

    get tokenEncryptionKey(): string {
        return this.config.unifii.appSecret as string;
    }

    private setStorageItem(key: string, v: string | null) {

        if (!v) {
            this.storage.removeItem(key);
        } else {
            this.storage.setItem(key, v);
        }
    }

}

import { InjectionToken } from '@angular/core';

// TODO used in LP, should the logic it provides be moved to feature flags instead ?
/** Override availability of items in Settings menu */
export interface SettingsConfig {
    hidePreviewMode?: boolean;
    hideRefreshApp?: boolean;
    hideChangeProject?: boolean;
    hideSyncForms?: boolean;
    hideMyProfile?: boolean;
    hideLogout?: boolean;
}

export const SettingsConfig = new InjectionToken<SettingsConfig>('SettingsConfig');

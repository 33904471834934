import { Injectable, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DownloadConfig, OptionsModalComponent, TableContainerManager, TableInputManager, TableInputs } from '@unifii/components';
import { AngularRouterLink, ColumnDisplayDescriptor, ContextProvider, DataPropertyDescriptor, FilterEntry, FilterValue, ModalService, TableConfig, getDefaultTableConfig, getTableCustomColumnsDisplayDescriptors } from '@unifii/library/common';
import { Client, FormData, Option, PermissionAction, Table, TableSourceType, hasLengthAtLeast } from '@unifii/sdk';
import { Subject } from 'rxjs';

import { Config } from 'config';
import { TableDisplayMode } from 'shell/content/content-node.component';
import { ShellFormService } from 'shell/form/shell-form.service';
import { Authentication } from 'shell/services/authentication';
import { PermissionsFunctions } from 'shell/services/permissions-functions';
import { FormDataPath, NewItemPath, TableSearchMinLength } from 'shell/shell-constants';
import { ShellTranslationKey } from 'shell/shell.tk';
import { BucketTableDataSource } from 'shell/table/form-data/bucket-table-datasource';
import { TableColumnFactory } from 'shell/table/table-column-factory';
import { TableInputManagerFactory } from 'shell/table/table-input-manager-factory';
import { ModuleInfo, TablePageConfig } from 'shell/table/table-page-config';

import { checkExportAllowedForRoles, checkShowCount } from '../table-functions';

@Injectable()
export class BucketTableContainerManager implements TableContainerManager<FormData, FilterValue, FilterEntry> {

    tableConfig: TableConfig<FormData>;
    tableIdentifier: string;
    showSearch: boolean;
    searchMinLength = TableSearchMinLength;
    addActionConfig?: boolean;
    downloadConfig?: DownloadConfig;
    customColumns: ColumnDisplayDescriptor[] = [];
    defaultSort: string | undefined;
    reload = new Subject<void>();
    update = new Subject<TableInputs<FilterValue>>();
    updateItem = new Subject<FormData>();
    inputManager: TableInputManager<FilterValue, FilterEntry>;
    formDefinitions: Option[] = []; // list of form definitions associated with the table
    help?: string;

    private showCount?: boolean;
    private downloadTableInputs?: TableInputs<FilterValue>;
    // private entityProperties?: string[]; // for include RQL operator - UNIFII-5557
    private client: Client;
    private auth: Authentication;
    private contextProvider: ContextProvider;
    private shellFormService: ShellFormService;
    private config: Config;
    private columnFactory: TableColumnFactory;
    private router: Router;
    private route: ActivatedRoute;
    private modalService: ModalService;
    private translateService: TranslateService;
    private table: Table;
    private moduleInfo: ModuleInfo;
    private addOptions: Option[] | undefined;

    // TODO drop the constructor?
    constructor() {
        this.client = inject(Client);
        this.auth = inject(Authentication);
        this.contextProvider = inject(ContextProvider);
        this.shellFormService = inject(ShellFormService);
        this.config = inject(Config);
        this.columnFactory = inject(TableColumnFactory);
        this.router = inject(Router);
        this.route = inject(ActivatedRoute);
        this.modalService = inject(ModalService);
        this.translateService = inject(TranslateService);
        this.moduleInfo = inject<ModuleInfo>(ModuleInfo);

        const { table, bucket, propertyDescriptors, addOptions, isSearchable } = inject(TablePageConfig);

        this.tableIdentifier = table.identifier;
        this.table = table;
        this.defaultSort = table.defaultSort;
        this.showSearch = isSearchable;
        this.help = table.help;

        this.inputManager = inject(TableInputManagerFactory).create(table, this.moduleInfo?.filter);

        if (table.hideExport !== true && checkExportAllowedForRoles(table, this.auth.userInfo?.roles ?? [])) {
            this.downloadConfig = {
                name: `${bucket}.csv`,
                getUrl: this.getDownloadUrl.bind(this),
            };
        }

        this.shellFormService.bucket = bucket as string;
        this.addActionConfig = !!addOptions?.length && (this.moduleInfo ? this.moduleInfo.canAdd : true);
        this.addOptions = addOptions;
        this.showCount = checkShowCount(this.config, table);
        this.customColumns = getTableCustomColumnsDisplayDescriptors(table.columns);
        this.tableConfig = this.getTableConfig(table, propertyDescriptors);
    }

    createDataSource(inputs?: TableInputs<FilterValue>) {
        // Store the inputs locally for the purpose of build the DownloadUrl
        this.downloadTableInputs = Object.assign({}, inputs);
        delete this.downloadTableInputs.sort;

        // DataSource for feed the Table
        return this.getDataSource(inputs);
    }

    async addActionCallback() {
        // if child of a detail component
        if (this.moduleInfo) {

            if (!this.addOptions || !hasLengthAtLeast(this.addOptions, 1)) {
                console.warn('bucket-table-container: Module needs addOptions to perform addActionCallback');

                return;
            }

            let selected: Option | undefined;

            if (this.addOptions.length === 1) {
                selected = this.addOptions[0];
            } else {
                selected = await this.modalService.openMedium(OptionsModalComponent, {
                    label: this.translateService.instant(ShellTranslationKey.FormBucketDialogAddFormTitle),
                    options: this.addOptions,
                });
            }

            if (!selected) {
                return;
            }

            const params = this.moduleInfo.filterLink ?? {};

            params.$definition = selected.identifier;
            void this.router.navigate([FormDataPath, this.table.source, NewItemPath, params]);

            return;
        }

        void this.router.navigate([NewItemPath], { relativeTo: this.route });
    }

    private async getDownloadUrl(): Promise<string> {
        // UNIFII-5557
        // const downloadIdentifiers = this.entityProperties?.filter((i) => this.tableConfig.columns.find((c) => c.name === i && !c.hidden));

        const dataSource = this.getDataSource(this.downloadTableInputs/* , downloadIdentifiers*/);
        const url = dataSource.getDownloadUrl();

        if (!url) {
            throw new Error('Failed to get download url');
        }

        const { token } = await this.client.getDownloadToken(url);

        return `${url}&_dlt=${token}`;
    }

    private getDataSource(inputs?: TableInputs<FilterValue>/* , include?: string[] UNIFII-5557*/): BucketTableDataSource {
        return new BucketTableDataSource({
            shellFormService: this.shellFormService,
            tableIdentifier: this.tableIdentifier,
            tableInputManager: this.inputManager,
            tableInputs: inputs,
            showCount: this.showCount,
            // include: include ?? this.entityProperties,
        });
    }

    private getTableConfig(table: Table, propertyDescriptors: Map<string, DataPropertyDescriptor>): TableConfig<FormData> {
        const columns = this.columnFactory.create(TableSourceType.Bucket, table.columns ?? [], propertyDescriptors, true);
        const tableConfig = getDefaultTableConfig(columns, `table_${table.identifier}`);

        tableConfig.row = { link: (item) => table.source ? this.getRowLink(item, table.source, table) : [] };

        /* UNIFII-5557
        const customColumnsIdentifiers = this.customColumns.map((cd) => cd.name);
        const entityProperties = tableConfig.columns
            .filter((c) => !customColumnsIdentifiers.includes(c.name))
            .map((c) => {
                // map DS field identifier to the mapped output identifiers `_id` `_display`
                const identifier = c.name;
                const property = propertyDescriptors.get(identifier);

                if (!property?.sourceConfig) {
                    return [identifier];
                }

                return [`${identifier}.${DataSourceIdTo}`, `${identifier}.${DataSourceDisplayTo}`];
            })
            .reduce((res, identifiers) => {
                res.push(...identifiers);

                return res;
            }, []);

        this.entityProperties = [...new Set(entityProperties)];
        */

        return tableConfig;
    }

    private getRowLink(formData: FormData, bucket: string, table: Table): AngularRouterLink {
        const isGranted = this.auth.getGrantedInfo(
            PermissionsFunctions.getBucketDocumentPath(this.config.unifii.projectId, bucket, `${formData.id}`),
            PermissionAction.Read,
            formData,
            this.contextProvider.get(),
        ).granted;

        if (isGranted) {

            if (this.moduleInfo) {
                return ['/', FormDataPath, bucket, formData.id, table.detail ? { mode: TableDisplayMode.Detail } : {}];
            }

            if (table.detail) {
                return [formData.id, { mode: TableDisplayMode.Detail }];
            }

            return [formData.id];
        }

        return [];
    }

}


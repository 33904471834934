import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { UfControl, UfControlGroup } from '@unifii/library/common';
import { Client, Interceptor, MeClient, PermissionAction, TenantClient, UfRequestError, UserInfo } from '@unifii/sdk';
import { MeCompleteRegistrationFormControl, UserFieldLabelService, UserFormContext, UserFormResourceType, UserKeys, UserProvisioningProvider } from '@unifii/user-provisioning';

import { Config } from 'config';
import { ProjectSelectionPath, UserAccessRootPath } from 'discover/discover-constants';
import { DiscoverTranslationKey } from 'discover/discover.tk';
import { AppError } from 'shell/errors/errors';
import { Authentication } from 'shell/services/authentication';
import { PermissionsFunctions } from 'shell/services/permissions-functions';
import { UserProvisioning } from 'shell/services/user-provisioning';
import { ShellTranslationKey } from 'shell/shell.tk';

type CompleteRegistrationLinkParams = {
    tenant?: string;
    token?: string;
};

@Component({
    selector: 'ud-complete-registration',
    templateUrl: 'complete-registration.html',
})
export class CompleteRegistrationComponent implements OnInit, OnDestroy {

    protected readonly discoverTK = DiscoverTranslationKey;
    protected readonly controlKeys = UserKeys;
    protected form: UfControlGroup;
    protected linkError: AppError;
    protected error: AppError | null;
    protected busy = true;
    protected labels = inject(UserFieldLabelService).labelDictionary;
    protected emailControl?: UfControl;
    protected usernameControl?: UfControl;
    protected firstNameControl?: UfControl;
    protected lastNameControl?: UfControl;
    protected phoneControl?: UfControl;
    protected passwordControl?: UfControl;

    private token: string;
    private user: UserInfo;
    private interceptor = inject(Interceptor);
    private authentication = inject(Authentication);
    private config = inject(Config);
    private router = inject(Router);
    private route = inject(ActivatedRoute);
    private translate = inject(TranslateService);
    private userFormContext = inject(UserFormContext);
    private meCompleteRegistrationFormCtrl = inject(MeCompleteRegistrationFormControl);
    private userProvisioning = inject<UserProvisioning>(UserProvisioningProvider);

    async ngOnInit() {
        this.userFormContext.set(UserFormResourceType.Me, PermissionAction.Update);
        
        let errorMessage = this.translate.instant(DiscoverTranslationKey.CompleteRegistrationErrorInvalidLink) as string;
        
        try {
            const { tenant, token } = this.route.snapshot.queryParams as CompleteRegistrationLinkParams;

            if (!tenant || !token) {
                throw new Error();
            }

            this.token = token;

            // Create new client without token storage
            const client = new Client(this.config.unifii, undefined, this.interceptor);
            const tenantClient = new TenantClient(client);
            const meClient = new MeClient(client);
            
            this.config.unifii.tenant = tenant;
            this.config.unifii.tenantSettings = await tenantClient.getSettings();
            this.user = await meClient.get(token);

            await this.amendUserProvisioningForNotAuthenticatedScope(client);

            // Clear username if it's an invitation_GUID
            if (this.user.username.startsWith('invitation_')) {
                this.user.username = '';
            }

            this.form = this.meCompleteRegistrationFormCtrl.buildRoot({ user: this.user, lockedConfig: undefined });
            this.emailControl = this.form.get(UserKeys.Email) as UfControl | undefined;
            this.usernameControl = this.form.get(UserKeys.Username) as UfControl | undefined;
            this.firstNameControl = this.form.get(UserKeys.FirstName) as UfControl | undefined;
            this.lastNameControl = this.form.get(UserKeys.LastName) as UfControl | undefined;
            this.phoneControl = this.form.get(UserKeys.Phone) as UfControl | undefined;
            this.passwordControl = this.form.get(UserKeys.Password) as UfControl | undefined;

            if (!this.passwordControl || !this.user.username || !this.user.email) {
                errorMessage = this.translate.instant(ShellTranslationKey.ErrorRequestUnauthorized);
                throw new Error();
            }

        } catch (e) {
            this.linkError = new UfRequestError(errorMessage);
        } finally {
            this.busy = false;
        }
    }

    ngOnDestroy() {
        this.revertAmendUserProvisioningForNotAuthenticatedScope();
    }

    protected async submit() {
        this.form.setSubmitted();

        if (this.form.invalid) {
            return;
        }

        try {
            this.error = null;
            this.busy = true;

            // Create new client without token storage
            const meClient = new MeClient(
                new Client(this.config.unifii, undefined, this.interceptor),
            );

            // patch user with form values
            const user = Object.assign({}, this.user, this.meCompleteRegistrationFormCtrl.toDataModel(this.form));

            await meClient.update(user, this.token);
            await this.authentication.login({ username: user.username, password: user.password as string });
            this.revertAmendUserProvisioningForNotAuthenticatedScope();
            void this.router.navigate(['/', UserAccessRootPath, ProjectSelectionPath]);

        } catch (error) {
            this.error = error as AppError;
        } finally {
            this.busy = false;
        }
    }

    private async amendUserProvisioningForNotAuthenticatedScope(client: Client) {
        const meClient = new MeClient(client);
        const permissions = await meClient.getPermissions(this.token);

        this.authentication.userPermissions = PermissionsFunctions.normalizePermissions(permissions);

        // Skip v0 calls to check for username and email duplicated
        this.userProvisioning.skipCheckEmail = true;
        this.userProvisioning.skipGetUserByUsername = true;
    }

    private revertAmendUserProvisioningForNotAuthenticatedScope() {
        // permissions has already been updated by login process
        this.userProvisioning.skipCheckEmail = true;
        this.userProvisioning.skipGetUserByUsername = true;
    }

}

import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { ErrorType, PublishedContent, Structure, StructureNodeType, ensureUfError } from '@unifii/sdk';

import { NavigationService } from 'shell/nav/navigation.service';
import { Authentication } from 'shell/services/authentication';

export const structureGuard: CanActivateFn = async() => {

    const content = inject(PublishedContent);
    const authentication = inject(Authentication);
    const nav = inject(NavigationService);

    const emptyStructure = { nodeId: '0', children: [], type: StructureNodeType.Empty } as Structure;

    try {
        // Override missing Structure with a default empty one
        nav.structure = await content.getStructure() || emptyStructure;

        // Success
        return true;

    } catch (e) {

        const error = ensureUfError(e);
        
        // TODO remove when this is handled in the client
        if (error.type === ErrorType.NotFound) {
            nav.structure = emptyStructure;

            return true;
        }

        authentication.logout({ error: error.type === ErrorType.Unauthorized ? undefined : error.message });

        return false;
    }
};

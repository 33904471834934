<div class="chart">
    <uf-progress class="large" />
    <div class="row">
        <h4 *ngIf="reportConfig.title" class="grow">{{ reportConfig.title }}</h4>
        <button *ngIf="reportConfig.chartType !== 'table' && !hideChart" (click)="downloadChartAsImage()" type="button"
            class="uf-action tertiary">
            <uf-icon name="download" />
        </button>
    </div>

    <us-chart *ngIf="chartConfig && !hideChart" [config]="chartConfig" />

    <uf-message *ngIf="hideChart" icon="alertSolid" class="info">
        <h3>{{ shellTK.ReportResultNoDataLabel | translate}}</h3>
    </uf-message>

    <div *ngIf="reportConfig.chartType === 'table' && reportData" class="table">
        <table class="uf-table accent">
            <thead>
                <tr>
                    <th *ngFor="let label of reportData.labels">
                        {{ $any(label).value ?? label }}
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let dataset of reportData.datasets">
                    <td *ngFor="let datum of dataset.data"
                        [ngClass]="{ 'bold': datum.bold === true, 'italic': datum.italic === true}">
                        <!-- TODO migrate hyperlink to start with / -->
                        <a *ngIf="datum.hyperlink; else cellValue" [routerLink]="'/' + datum.hyperlink">
                            <ng-container *ngTemplateOutlet="cellValue" />
                        </a>
                        <ng-template #cellValue>
                            {{ datum.value ?? datum }}
                        </ng-template>
                    </td>
                </tr>
                <tr *ngIf="!reportData.datasets || !reportData.datasets.length">
                    <td [attr.colspan]="reportData.labels.length" class="empty">
                        {{ shellTK.ReportResultNoDataLabel | translate}}
                    </td>
                </tr>
            </tbody>
        </table>
    </div>

</div>
import { Inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ContextProvider, FileUploader, RuntimeDefinition, RuntimeDefinitionAdapter } from '@unifii/library/common';
import { Client, ClientGetOptions, Definition, ErrorType, FormData, FormDataClient, PermissionAction, PublishedContent, Query, UfRequestError, ensureUfError } from '@unifii/sdk';

import { Config } from 'config';
import { ErrorService } from 'shell/errors/error.service';
import { AppError } from 'shell/errors/errors';
import { ShellFileUploader } from 'shell/form/shell-file-uploader';
import { OfflineQueue } from 'shell/offline/forms/offline-queue';
import { Authentication } from 'shell/services/authentication';
import { PermissionsFunctions } from 'shell/services/permissions-functions';
import { ShellTranslationKey } from 'shell/shell.tk';

export enum SaveResult {
    Conflict,
    Failed,
    Queued,
    Succeed
}

export interface SaveOutput {
    result: SaveResult;
    data?: FormData;
}

/**
 * This class provide an advanced manage of (Definition, FormData and its Revisions)
 * integrating Permissions and OfflineQueue strategies
 */
@Injectable({ providedIn: 'root' })
export class ShellFormService {

    private formDataClient: FormDataClient | null = null;
    private _bucket: string | null = null;

    constructor(
        @Inject(Config) private config: Config,
        private client: Client,
        private offlineQ: OfflineQueue,
        @Inject(Authentication) private auth: Authentication,
        @Inject(PublishedContent) private content: PublishedContent,
        @Inject(ContextProvider) private contextProvider: ContextProvider,
        private translate: TranslateService,
        private errorService: ErrorService,
        private runtimeDefinitionAdapter: RuntimeDefinitionAdapter,
    ) { }

    set bucket(b: string) {
        this._bucket = b;
        this.formDataClient = new FormDataClient(this.client, {
            bucket: this._bucket,
            preview: this.config.unifii.preview,
            projectId: this.config.unifii.projectId,
        });
    }

    get bucket(): string {
        return this._bucket as string;
    }

    getFileUploader(dataId: string): FileUploader {
        if (this.formDataClient == null) {
            throw this.bucketNotSetError;
        }

        return new ShellFileUploader(this.formDataClient, this.offlineQ, dataId);
    }

    getFormData(formDataId: string): Promise<FormData> {
        if (this.formDataClient == null) {
            throw this.bucketNotSetError;
        }

        this.guardReadFormData(formDataId);

        return this.formDataClient.get(formDataId);
    }

    getFormDataRevision(formDataId: string): Promise<string | undefined> {
        if (this.formDataClient == null) {
            throw this.bucketNotSetError;
        }

        this.guardReadFormData(formDataId);

        return this.formDataClient.getRevision(formDataId, { skipExecutionEmit: true });
    }

    async getFormDefinition(identifier: string, version?: number): Promise<RuntimeDefinition> {
        try {
            this.guardReadForm(identifier);
            
            const definition = await this.content.getForm(identifier, version);

            // version can be 0 so !version won't be a good condition
            if (version == null) {
                // no specific version requested, loaded latest hence is compatible with both hasRollingVersion scenarios
                return this.runtimeDefinitionAdapter.transform(definition);
            }

            /* Form Definition has been potentially migrated from .hasRollingVersion undefined/false to true
            * We need to load the Schema to verify what is the actual rollingVersion status of the bucket
            */
            const schema = await this.content.getBucket(definition.bucket as string);

            if (!schema.hasRollingVersion) {
                return this.runtimeDefinitionAdapter.transform(definition);
            }

            // Requested a specific version for a rollingVersion Schema, the latest need to be provided instead
            this.guardReadForm(identifier, definition);
            const latestDefinition = await this.content.getForm(identifier);

            if (latestDefinition.version !== definition.version) {
                console.warn(`ShellFormService.getFormDefinition - requested for rollingVersion form ${identifier} version ${definition.version} instead of ${latestDefinition.version}, version ${latestDefinition.version} will be used.`);
            }

            return this.runtimeDefinitionAdapter.transform(latestDefinition);

        } catch (e) {
            throw this.errorService.createLoadError(identifier, e);
        }
    }

    query(query: Query, options?: ClientGetOptions): Promise<FormData[]> {
        if (this.formDataClient == null) {
            throw this.bucketNotSetError;
        }

        this.guardListFormDataDocuments();

        return this.formDataClient.query(query, options);
    }

    count(query: Query, options?: ClientGetOptions): Promise<number | undefined> {
        if (this.formDataClient == null) {
            throw this.bucketNotSetError;
        }

        if (this.config.unifii.tenantSettings?.features?.indexing !== true) {
            return Promise.resolve(undefined);
        }

        this.guardListFormDataDocuments();

        return this.formDataClient.count(query, options);
    }

    getDownloadUrl(query: Query): string {
        if (this.formDataClient == null) {
            throw this.bucketNotSetError;
        }

        return this.formDataClient.getDownloadUrl(query);
    }

    async save(data: FormData, definition: Definition | RuntimeDefinition): Promise<SaveOutput> {

        let result = SaveResult.Failed;

        try {

            if (this.formDataClient == null) {
                throw this.bucketNotSetError;
            }

            await this.offlineQ.save(data, definition, { skipNotify: true });
            result = SaveResult.Queued;

            const uploadResult = await this.offlineQ.upload(data.id as string, { revision: data._rev });

            if (!uploadResult?.formData) {
                throw new Error('Save failed');
            }

            result = SaveResult.Succeed;

            return { result, data: uploadResult.formData };

        } catch (e) {
            console.warn('ShellFormService.save - error uploading form:', e);

            if (ensureUfError(e).type === ErrorType.Conflict) {
                result = SaveResult.Conflict;
                console.log('ShellFormService.save - conflict, form removed from the OfflineQueue');
                await this.offlineQ.delete(data.id as string);
            }

            if (result === SaveResult.Queued) {
                this.offlineQ.emitAddition();
            }

            return { result };
        }
    }

    private guardReadFormData(formDataId: string) {
        if (!this.auth.getGrantedInfoWithoutCondition(
            PermissionsFunctions.getBucketDocumentPath(
                this.config.unifii.projectId, this.bucket, formDataId,
            ), PermissionAction.Read,
        ).granted) {
            throw this.forbiddenError;
        }
    }

    private guardListFormDataDocuments() {
        if (!this.auth.getGrantedInfoWithoutCondition(
            PermissionsFunctions.getBucketDocumentsPath(
                this.config.unifii.projectId, this.bucket,
            ), PermissionAction.List,
        ).granted) {
            throw this.forbiddenError;
        }
    }

    private guardReadForm(identifier: string, definition?: Definition) {
        let granted: boolean;

        if (definition != null) {
            granted = this.auth.getGrantedInfo(
                PermissionsFunctions.getFormPath(this.config.unifii.projectId, definition.identifier),
                PermissionAction.Read, definition, this.contextProvider.get(),
            ).granted;
        } else {
            granted = this.auth.getGrantedInfoWithoutCondition(
                PermissionsFunctions.getFormPath(this.config.unifii.projectId, identifier),
                PermissionAction.Read,
            ).granted;
        }

        if (!granted) {
            throw this.forbiddenError;
        }
    }

    private get forbiddenError(): AppError {
        return new UfRequestError(this.translate.instant(ShellTranslationKey.ErrorRequestForbidden), ErrorType.Forbidden);
    }

    private get bucketNotSetError(): AppError {
        return new UfRequestError('Set bucket first');
    }

}

import { Component, Inject, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CommonTranslationKey, SharedTermsTranslationKey, UfControl, UfControlGroup, ValidatorFunctions } from '@unifii/library/common';
import { Dictionary, TenantSettings, UfRequestError, ensureUfRequestError, isDictionary } from '@unifii/sdk';
import { Subscription, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

import { Config, Environment } from 'config';
import { PasswordChangePath, ProjectSelectionPath, TenantSelectionPath, UserAccessRootPath } from 'discover/discover-constants';
import { DiscoverContext } from 'discover/discover-context';
import { DiscoverTranslationKey } from 'discover/discover.tk';
import { ErrorService } from 'shell/errors/error.service';
import { AppError } from 'shell/errors/errors';
import { Authentication } from 'shell/services/authentication';
import { SavedUsersService } from 'shell/services/saved-users.service';
import { UserAccessManager } from 'shell/services/user-access-manager';

@Component({
    templateUrl: './login-form.html',
    styleUrls: ['../../../shell/styles/external-branding.less', './login.less'],
})
export class LoginFormComponent implements OnDestroy {

    readonly sharedTermsTK = SharedTermsTranslationKey;
    readonly commonTK = CommonTranslationKey;
    readonly discoverTK = DiscoverTranslationKey;

    protected formGroup: UfControlGroup;
    protected inProgress: boolean;
    protected rememberMeEnabled: boolean;

    private params: { projectId?: string } = {};
    private subscriptions = new Subscription();

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        public context: DiscoverContext,
        @Inject(Config) private config: Config,
        @Inject(Authentication) private auth: Authentication,
        @Inject(Environment) private env: Config,
        private errorService: ErrorService,
        private translate: TranslateService,
        private userAccessManager: UserAccessManager,
        savedUsersService: SavedUsersService,
    ) {
        this.rememberMeEnabled = savedUsersService.enabled;

        this.formGroup = this.rootControl; // required so translations can be added to errors

        this.subscriptions.add(
            combineLatest([this.route.params, this.route.queryParams]).pipe(
                map((results) => ({ ...results[0], ...results[1] })))
                .subscribe((allParams) => { this.paramsChange(allParams); }),
        );

    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
        this.userAccessManager.showError(null);
    }

    protected async authorize(): Promise<void> {

        this.userAccessManager.showError(null);

        if (this.formGroup.invalid) {
            this.formGroup.setSubmitted();

            return;
        }

        this.inProgress = true;
        const username = this.formGroup.controls.username?.value.trim();
        const password = this.formGroup.controls.password?.value;
        const rememberMe = this.formGroup.controls.rememberMe?.value;

        try {

            await this.auth.login({ username, password, rememberMe });

            void this.router.navigate(['/', UserAccessRootPath, ProjectSelectionPath, this.params]);

        } catch (e) {
            const error = ensureUfRequestError(e);

            if (isDictionary(error.data) && !!error.data.passwordChangeRequired) {
                this.auth.oldPassword = password;
                void this.router.navigate(['/', PasswordChangePath]);

                return;
            }

            this.userAccessManager.showError(this.getAuthError(error));

        } finally {
            this.inProgress = false;
        }
    }

    protected changeTenant() {

        const { tenant } = this.config.unifii;

        // Ensure auth information is clear
        this.auth.clear();

        this.config.unifii.tenantSettings = undefined;
        this.config.unifii.tenant = undefined;

        void this.router.navigate(['/', UserAccessRootPath, TenantSelectionPath, { tenant }]);
    }

    private paramsChange(allParams: Dictionary<string>) {

        if (allParams.username) {
            this.formGroup.controls.username?.setValue(allParams.username);
        }

        if (allParams.rememberMe) {
            this.formGroup.controls.rememberMe?.setValue(allParams.rememberMe);
        }

        if (allParams.projectId) {
            this.params.projectId = allParams.projectId;
        }

    }

    private getAuthError(error: UfRequestError): AppError {

        if (error.message === this.translate.instant(DiscoverTranslationKey.SelectProjectErrorNoProjects)) {
            return error;
        }

        if (isDictionary(error.data) && error.data.error === 'invalid_grant') {
            return this.errorService.createError(error.data.error_description, this.errorService.invalidUsernameOrPasswordErrorMessage);
        }

        return this.errorService.createError(this.errorService.unhandledErrorMessage);
    }

    private get rootControl(): UfControlGroup {

        return new UfControlGroup({
            username: new UfControl(ValidatorFunctions.required(this.translate.instant(SharedTermsTranslationKey.ValidatorValueRequired))),
            password: new UfControl(ValidatorFunctions.required(this.translate.instant(SharedTermsTranslationKey.ValidatorValueRequired))),
            rememberMe: new UfControl(),
        });
    }

    get canChangeCompany(): boolean {
        return !this.env.unifii.tenant;
    }

    get tenantSettings(): TenantSettings | undefined {
        return this.config.unifii.tenantSettings;
    }

}

import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';

import { Authentication } from 'shell/services/authentication';

/**
 * Ensure no user is currently logged in
 */
export const anonymousGuard: CanActivateFn = (): boolean => {

    const auth = inject(Authentication);
    const router = inject(Router);

    if (!auth.isAuthenticated) {
        return true;
    }

    void router.navigate(['/']);

    return false;
};
